import {Image, Row, Col, CustomQRcode} from "../components";
import {resolucionHacienda, urlPublico} from "../constants";
import {roundNumber, numberFormat} from "../helpers";

const logoSizes = {
  "01": "logo-largo-carta-25",
  "02": "logo-largo-carta-35",
  "03": "logo-largo-carta-50"
};

export const CustomFacturaCarta = ({facturaRef, factura, logo, logoLargo, logoSize, fontSize, decimales, formato, codigoMoneda}) => {
  const getNumber = (number) => numberFormat(roundNumber(number ?? 0, decimales ?? 5), formato ?? "number");
  const textoCarta = `texto-carta-${fontSize || "01"}`;
  const textoMedioCarta = `texto-medio-carta-${fontSize || "01"}`;
  const textoClaveCarta = `texto-clave-carta-${fontSize || "01"}`;

  return(
    <div className="display-none width-carta">
      <div className="width-carta" ref={facturaRef}>
        <Row className="section-carta">
          {logo ? <Col className="container-logo-carta" xs={logoLargo ? 12 : (logoSize === "01" ? 1 : 2)}><Image className={logoLargo ? logoSizes[logoSize] : "logo-carta"} src={logo} /></Col> : null}
          <Col className={textoCarta} xs={logo && !logoLargo ? (logoSize === "01" ? 7 : 6) : 8}>
            <p className="texto-linea-carta"><strong>Clave:</strong> <span className={logo && !logoLargo ? textoClaveCarta : "texto-linea-carta"}>{factura.clave}</span></p>
            <p className="texto-linea-carta"><strong>Consecutivo:</strong> {factura.numeroConsecutivo}</p>
            <p className="texto-linea-carta"><strong>Condici&oacute;n:</strong> {factura.condicion}</p>
            <p className="texto-linea-carta"><strong>Medio de Pago:</strong> {factura.medios}</p>
          </Col>
          <Col className={textoCarta} xs={4}>
            <p className="texto-linea-carta"><strong><u>{factura.tipoDocumento}</u></strong></p>
            <p className="texto-linea-carta"><strong>Fecha:</strong> {factura.fecha}</p>
            <p className="texto-linea-carta"><strong>Actividad:</strong> {factura.codigoActividad}</p>
            <p className="texto-linea-carta"><strong>Moneda:</strong> {factura.resumenFactura.codigoTipoMoneda || codigoMoneda || "CRC"}</p>
          </Col>
        </Row>
        {factura.otros?.otroTexto?.[0] ? (
          <Row className="section-carta">
            <Col className={textoCarta}>
              <p className="texto-linea-carta"><strong>Observaciones:</strong> {factura.otros.otroTexto[0]}</p>
            </Col>
          </Row>
        ) : null}
        {factura.informacionReferencia?.length ? (
          factura.informacionReferencia.map((referencia, index) => (
            <Row key={`referencia${index}`} className="section-carta">
              <Col className={textoCarta} xs={8}>
                <p className="texto-linea-carta"><strong>Clave:</strong> {referencia.numero ?? ""}</p>
                <p className="texto-linea-carta"><strong>Raz&oacute;n:</strong> {referencia.razon ?? ""}</p>
              </Col>
              <Col className={textoCarta} xs={4}>
                <p className="texto-linea-carta"><strong><u>Documento de referencia</u></strong></p>
                <p className="texto-linea-carta"><strong>Consecutivo:</strong> {referencia.numero?.substring(21, 41) ?? ""}</p>
              </Col>
            </Row>
          ))
        ) : null}
        <Row className="section-carta">
          <Col className={textoCarta} xs={8}>
            <p className="texto-linea-carta"><strong>Nombre:</strong> {factura.emisor.nombreComercial || factura.emisor.nombre}</p>
            <p className="texto-linea-carta"><strong>Correo:</strong> {factura.emisor.correoElectronico}</p>
            <p className="texto-linea-carta"><strong>Direcci&oacute;n:</strong> {factura.emisor.ubicacion?.otrasSenas || "--"}</p>
          </Col>
          <Col className={textoCarta} xs={4}>
            <p className="texto-linea-carta"><strong><u>Emisor</u></strong></p>
            <p className="texto-linea-carta"><strong>Identificaci&oacute;n:</strong> {factura.emisor.identificacion.numero}</p>
            <p className="texto-linea-carta"><strong>Tel&eacute;fono:</strong> {factura.emisor.telefono?.numTelefono || "--"}</p>
          </Col>
        </Row>
        {factura.receptor ? (
          <Row className="section-carta">
            <Col className={textoCarta} xs={8}>
              <p className="texto-linea-carta"><strong>Nombre:</strong> {factura.receptor.nombreComercial || factura.receptor.nombre}</p>
              <p className="texto-linea-carta"><strong>Correo:</strong> {factura.receptor.correoElectronico}</p>
              <p className="texto-linea-carta"><strong>Direcci&oacute;n:</strong> {factura.receptor.ubicacion?.otrasSenas || factura.receptor.otrasSenasExtranjero || "--"}</p>
            </Col>
            <Col className={textoCarta} xs={4}>
              <p className="texto-linea-carta"><strong><u>Receptor</u></strong></p>
              <p className="texto-linea-carta"><strong>Identificaci&oacute;n:</strong> {factura.receptor.identificacion.numero || factura.receptor.identificacionExtranjero}</p>
              <p className="texto-linea-carta"><strong>Tel&eacute;fono:</strong> {factura.receptor.telefono?.numTelefono || "--"}</p>
            </Col>
          </Row>
        ) : null}
        <Row className="section-carta">
          <Col className={textoCarta}>
            <Row><Col className={textoCarta}><strong><u>Detalle</u></strong></Col></Row>
            <Row>
              <Col className={textoMedioCarta} xs={2}><strong>Cabys</strong></Col>
              <Col className={textoMedioCarta} xs={3}><strong>Nombre</strong></Col>
              <Col className={textoMedioCarta} xs={1}><strong>Cantidad</strong></Col>
              <Col className={textoMedioCarta} xs={1}><strong>Precio</strong></Col>
              <Col className={textoMedioCarta} xs={1}><strong>Descuento</strong></Col>
              <Col className={textoMedioCarta} xs={1}><strong>Subtotal</strong></Col>
              <Col className={textoMedioCarta} xs={1}><strong>Impuestos</strong></Col>
              <Col className={textoMedioCarta} xs={2}><strong>Total</strong></Col>
            </Row>
            {factura.lineas.map((linea, index) => (
              <Row key={`linea${index}`}>
                <Col className={textoMedioCarta} xs={2}>{linea.codigo ?? ""}</Col>
                <Col className={textoMedioCarta} xs={3}>{linea.detalle ?? ""}</Col>
                <Col className={textoMedioCarta} xs={1}>{numberFormat(Number(linea.cantidad ?? 0), formato ?? "number")}</Col>
                <Col className={textoMedioCarta} xs={1}>{getNumber(linea.precioUnitario)}</Col>
                <Col className={textoMedioCarta} xs={1}>{getNumber(linea.descuento?.[0]?.montoDescuento)}</Col>
                <Col className={textoMedioCarta} xs={1}>{getNumber(linea.subTotal)}</Col>
                <Col className={textoMedioCarta} xs={1}>{getNumber(linea.impuestoNeto)}</Col>
                <Col className={textoMedioCarta} xs={2}>{getNumber(linea.montoTotalLinea)}</Col>
              </Row>
            ))}
          </Col>
        </Row>
        <Row className="section-carta">
          <Col className={textoCarta}>
            <Row><Col className={textoCarta}><strong><u>Resumen</u></strong></Col></Row>
            <Row>
              <Col className={textoCarta} xs={2}>
                <p className="texto-linea-carta"><strong>Servicios</strong></p>
                <p className="texto-linea-carta"><strong>Exento:</strong></p>
                <p className="texto-linea-carta"><strong>Exonerado:</strong></p>
                <p className="texto-linea-carta"><strong>Gravado:</strong></p>
              </Col>
              <Col className={textoCarta} xs={2}>
                <p className="texto-linea-carta">&#160;</p>
                <p className="texto-linea-carta">{getNumber(factura.resumenFactura.totalServExentos)}</p>
                <p className="texto-linea-carta">{getNumber(factura.resumenFactura.totalServExonerado)}</p>
                <p className="texto-linea-carta">{getNumber(factura.resumenFactura.totalServGravados)}</p>
              </Col>
              <Col className={textoCarta} xs={2}>
                <p className="texto-linea-carta"><strong>Mercanc&iacute;as</strong></p>
                <p className="texto-linea-carta"><strong>Exento:</strong></p>
                <p className="texto-linea-carta"><strong>Exonerado:</strong></p>
                <p className="texto-linea-carta"><strong>Gravado:</strong></p>
              </Col>
              <Col className={textoCarta} xs={2}>
                <p className="texto-linea-carta">&#160;</p>
                <p className="texto-linea-carta">{getNumber(factura.resumenFactura.totalMercanciasExentas)}</p>
                <p className="texto-linea-carta">{getNumber(factura.resumenFactura.totalMercExonerada)}</p>
                <p className="texto-linea-carta">{getNumber(factura.resumenFactura.totalMercanciasGravadas)}</p>
              </Col>
              <Col className={textoCarta} xs={2}>
                <p className="texto-linea-carta"><strong>Totales</strong></p>
                <p className="texto-linea-carta"><strong>Descuentos:</strong></p>
                <p className="texto-linea-carta"><strong>Impuesto:</strong></p>
                <p className="texto-linea-carta"><strong>Total:</strong></p>
              </Col>
              <Col className={textoCarta} xs={2}>
                <p className="texto-linea-carta">&#160;</p>
                <p className="texto-linea-carta">{getNumber(factura.resumenFactura.totalDescuentos)}</p>
                <p className="texto-linea-carta">{getNumber(factura.resumenFactura.totalImpuesto)}</p>
                <p className="texto-linea-carta"><strong>{getNumber(factura.resumenFactura.totalComprobante)}</strong></p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="section-carta">
          <div className="code-carta"><CustomQRcode url={urlPublico(factura.clave)} /></div>
          <p align="center" className="texto-linea-carta">{resolucionHacienda()}</p>
        </Row>
      </div>
    </div>
  );
};