import {useState, useEffect, useRef} from "react";
import {useReactToPrint} from "react-to-print";
import {useVenta, usePageHandlerReduced, useConfiguracion} from "../customHooks";
import {
  listCuentaCodigosActividadRequest, searchClienteRequest, searchProductoRequest, initForm, crearFacturaRequest, crearTiqueteRequest,
  getExoneracionClienteRequest, estadoCajaRequest, updateCajaRequest, select, insert, remove, cleanStringDate, lowerCase, setBoolean
} from "../helpers";
import {
  Container, Form, Row, Col, Button, Table, Modal, CustomAlert, ListGroup, Card, CustomSelectField, CustomTextField, CustomSearchField, CustomCheckField,
  CustomFacturaPos, CustomFacturaCarta, CustomFacturaPosCarta, Accordion
} from "../components";

export const VentaPage = ({controller}) => {
  const pageHandler = usePageHandlerReduced(useVenta);
  const configuracion = useConfiguracion();
  const [comprobanteImprimir, setComprobanteImprimir] = useState({});
  const [nombreComprobante, setNombreComprobante] = useState("");
  const comprobanteRef = useRef();
  const handlePrint = useReactToPrint({content: () => comprobanteRef.current, documentTitle: nombreComprobante});
  const cuenta = controller.usuario.cuenta.get;
  const cuentaData = controller.usuario.cuenta.data;
  const logo = cuentaData.imagen ? `data:image/${cuentaData.imagenTipo ?? "png"};base64, ${cuentaData.imagen}` : false;
  const sucursal = controller.usuario.sucursal.get;
  const sucursalData = controller.usuario.sucursal.data;
  const receptor = pageHandler.item.receptor.getObject;
  const producto = pageHandler.item.linea.getLinea.producto;

  useEffect(() => {
    if (pageHandler.item.codigoActividad.value) {
      insert(`c${cuenta}s${sucursal}venta`, pageHandler.item.getData());
    }
  }, [
    JSON.stringify(receptor), pageHandler.item.isMinorista.value, pageHandler.item.codigoActividad.value,
    pageHandler.item.condicionVenta.value, JSON.stringify(pageHandler.item.linea.getLinea)
  ]);

  useEffect(() => {
    setComprobanteImprimir({});
    if (cuenta) {
      if (pageHandler.isMounted) {
        configuracion.setObject(cuentaData.features);
      }
    }
  }, [cuentaData]);

  useEffect(() => {
    pageHandler.item.linea.tipoPrecio.setValue(configuracion.values.tipoPrecio);
    pageHandler.item.linea.tipoDescuento.setValue(configuracion.values.tipoDescuento);
  }, [JSON.stringify(configuracion.values)]);

  useEffect(() => {
    if (cuenta && sucursal) {
      const loadData = async() => {
        try {
          const ventaData = await select(`c${cuenta}s${sucursal}venta`) || {};
          const codigos = await listCuentaCodigosActividadRequest(cuenta);
          const caja = await estadoCajaRequest(sucursal);
          if (pageHandler.isMounted) {
            pageHandler.item.setObject(cuentaData, sucursalData, codigos, ventaData);
            if (caja) {
              pageHandler.item.setCajaId(caja.id);
            }
          }
        } catch(error) {
          console.log(error);
        }
      }
      loadData();
    }
  }, [sucursalData]);

  useEffect(() => {
    if (pageHandler.item.linea.agregarExoneracion.value) {
      const loadExoneraciones = async() => {
        const response = await getExoneracionClienteRequest(cuenta, pageHandler.item.receptor.identificacion);
        if (response.length > 0) {
          const exoneraciones = response.filter(item => {
            if (item.poseeCabys) {
              return item.cabys.includes(pageHandler.item.linea.producto.codigo);
            }
            return true;
          });
          if (pageHandler.isMounted) {
            pageHandler.item.linea.setExoneraciones(exoneraciones);
          }
        }
      };
      loadExoneraciones();
    } else {
      pageHandler.item.linea.setExoneraciones([]);
    }
  }, [pageHandler.item.linea.agregarExoneracion.value]);

  useEffect(() => {
    if (comprobanteImprimir.isSet) {
      handlePrint();
    }
  }, [comprobanteImprimir]);

  const handleVenta = (event) => {
    const form = initForm(event);
    if (form.checkValidity() === true) {
      const crearVenta = async() => {
        try {
          pageHandler.setIsLoading(true);
          const crearVenta = pageHandler.item.receptor.isSet ? crearFacturaRequest : crearTiqueteRequest;
          const ventaData = pageHandler.item.getObject();
          if (ventaData.codigoActividad && ventaData.codigoActividad.length > 5) {
            const response = await crearVenta(cuenta, sucursal, ventaData);
            await remove(`c${cuenta}s${sucursal}venta`);
            if (pageHandler.isMounted) {
              setNombreComprobante(response.numeroConsecutivo);
              pageHandler.setOpenModal(false);
              if (pageHandler.item.cajaId) {
                updateCajaRequest(pageHandler.item.cajaId, pageHandler.item.getCajaObject());
              }
              setComprobanteImprimir(pageHandler.item.mapObject({...ventaData, clave: response.clave, numeroConsecutivo: response.numeroConsecutivo}));
              pageHandler.item.reset(false);
            }
          } else {
            pageHandler.setAlert("Debe seleccionar una actividad");
            pageHandler.setAlertType("warning");
            pageHandler.setShowAlert(true);
          }
          pageHandler.setIsLoading(false);
        } catch(error) {
          console.log(error);
          pageHandler.setIsLoading(false);
        }
      }
      crearVenta();
    } else {
      pageHandler.setFormValidated(true);
    }
  };

  const getFieldFromValue = (item, value) => {
    if (lowerCase(item.nombreComercial).includes(lowerCase(value))) {
      return item.nombreComercial;
    }
    return item.nombre;
  }

  const handleSearchCliente = (text) => {
    pageHandler.item.setLoadingClientes(true);
    const searchClientes = async() => {
      try {
        const result = await searchClienteRequest(text, cuenta, {page: 0, size: 50, sort: "nombre,asc"});
        if (pageHandler.isMounted) {
          pageHandler.item.setClientes(result.content.map(element => ({object: element, value: `${getFieldFromValue(element, text)} (${element.identificacion.numero})`})));
          pageHandler.item.setLoadingClientes(false);
        }
      } catch(error) {
        console.log(error);
      }
    }
    searchClientes();
  };

  const handleSearchProducto = (text) => {
    pageHandler.item.linea.setLoadingProductos(true);
    const searchProductos = async() => {
      try {
        const result = await searchProductoRequest(sucursal, text, {page: 0, size: 50, sort: "id,asc"});
        if (pageHandler.isMounted) {
          pageHandler.item.linea.setProductos(result.content.map(element => ({object: element, value: `${element.detalle} (${element.codigoComercial})`})));
          pageHandler.item.linea.setLoadingProductos(false);
        }
      } catch(error) {
        console.log(error);
      }
    }
    searchProductos();
  };

  const handleEnterCliente = (e) => {
    if (["Enter", "NumpadEnter"].includes(e.code)) {
      handleSearchCliente(e.target.value);
    }
  };

  const handleEnterProducto = (e) => {
    if (["Enter", "NumpadEnter"].includes(e.code)) {
      handleSearchProducto(e.target.value);
    }
  };

  const handleProducto = (selected) => {
    if (selected[0]) {
      pageHandler.item.linea.setLinea(pageHandler.item.isMinorista.select.value ? selected[0].object.precioUnitarioMinorista : selected[0].object.precioUnitarioMayorista, selected[0].object);
    } else {
      pageHandler.item.linea.handleCancelarLinea();
    }
  };

  const openMediosPagoModal = () => {
    pageHandler.item.initializeMedioPago();
    pageHandler.item.linea.handleCancelarLinea();
    pageHandler.setOpenModal(true);
  }

  const getImpresion = () => {
    if (configuracion.values.printSize === "01") {
      return <CustomFacturaPos facturaRef={comprobanteRef} factura={comprobanteImprimir} fontSize={configuracion.values.fontSize} decimales={configuracion.values.cantidadDecimales} formato={configuracion.values.numeroFormato} codigoMoneda={pageHandler.item.codigoMoneda.value} />;
    } else if (configuracion.values.printSize === "02") {
      return <CustomFacturaCarta facturaRef={comprobanteRef} factura={comprobanteImprimir} logo={logo} logoLargo={configuracion.values.logoProporcion === "02"} logoSize={configuracion.values.logoSize} fontSize={configuracion.values.fontSize} decimales={configuracion.values.cantidadDecimales} formato={configuracion.values.numeroFormato} codigoMoneda={pageHandler.item.codigoMoneda.value} />;
    } else if (configuracion.values.printSize === "03") {
      return <CustomFacturaPosCarta facturaRef={comprobanteRef} factura={comprobanteImprimir} logo={logo} logoSize={configuracion.values.logoSize} fontSize={configuracion.values.fontSize} decimales={configuracion.values.cantidadDecimales} formato={configuracion.values.numeroFormato} codigoMoneda={pageHandler.item.codigoMoneda.value} />;
    }
  }

  if (sucursal) {
    return (
      <Container className="full-container">
        {comprobanteImprimir.isSet ? getImpresion() : null}
        <Modal size="lg" centered show={pageHandler.item.showCliente} onHide={() => pageHandler.item.setShowCliente(false)} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title id="infoTitle">Cliente</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><strong>Nombre</strong>: {receptor.nombre ?? "--"}</p>
            <p><strong>Nombre Comercial</strong>: {receptor.nombreComercial ?? "--"}</p>
            <p><strong>Identificaci&oacute;n</strong>: {receptor.identificacion?.numero ?? "--"}</p>
            <p><strong>Tel&eacute;fono</strong>: {receptor.telefono?.numTelefono ?? "--"}</p>
            <p><strong>Correo Electr&oacute;nico</strong>: {receptor.correoElectronico ?? "--"}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => pageHandler.item.setShowCliente(false)}>Regresar</Button>
          </Modal.Footer>
        </Modal>
        <Modal size="lg" centered show={pageHandler.item.showProducto} onHide={() => pageHandler.item.setShowProducto(false)} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title id="infoTitle">{setBoolean(producto.isProducto) ? "Producto" : "Servicio"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><strong>Detalle</strong>: {producto.detalle ?? "--"}</p>
            <p><strong>C&oacute;digo Cabys</strong>: {producto.codigo ?? "--"}</p>
            <p><strong>C&oacute;digo Interno</strong>: {producto.codigoComercial ?? "--"}</p>
            <p><strong>Stock</strong>: {producto.stock ?? "--"}</p>
            <p><strong>Unidad</strong>: {producto.unidadMedida ?? "--"}</p>
            <p><strong>Precio Mayorista</strong>: {producto.precioUnitarioMayorista ?? "--"}</p>
            <p><strong>Precio Minorista</strong>: {producto.precioUnitarioMinorista ?? "--"}</p>
            <p><strong>Porcentaje Impuestos</strong>: {producto.impuestoTarifa ?? "--"}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => pageHandler.item.setShowProducto(false)}>Regresar</Button>
          </Modal.Footer>
        </Modal>
        <Modal
          size="xl"
          centered
          show={pageHandler.openModal}
          onHide={() => pageHandler.setOpenModal(false)}
          animation={false}
        >
          <Form noValidate validated={pageHandler.formValidated} onSubmit={!pageHandler.isLoading ? handleVenta : null}>
            <Modal.Header closeButton>
              <Modal.Title id="guardarTitle">Observaciones y medio de Pago</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CustomAlert
                  type="danger"
                  dismissible
                  body={pageHandler.alert}
                  show={pageHandler.showAlert}
                  onClose={pageHandler.setShowAlert}
              />
              <Row>
                <Col>
                  <CustomTextField id="observaciones" label="Observaciones" placeholder="Observaciones" input={pageHandler.item.observaciones.input} />
                </Col>
              </Row>
              {
                pageHandler.item.medioPago.map((element, index) =>
                  <Row key={`medioPago${index}`}>
                    <Col lg>
                      <CustomSelectField
                        id={`medioPago${index}`} label={`Medio de Pago ${index + 1}`} options={pageHandler.item.mediosPago}
                        input={{value: element, onChange: (e) => pageHandler.item.changeMedioPago(index, e.target.value)}}
                      />
                    </Col>
                    <Col lg>
                      <CustomTextField
                        id={`medioPagoMonto${index}`} label={`Monto ${index + 1}`} placeholder="Monto"
                        input={{value: pageHandler.item.medioPagoMonto[index], onChange: (e) => pageHandler.item.changeMedioPagoMonto(index, e.target.value)}}
                        append={<Button variant="danger" disabled={pageHandler.isLoading || pageHandler.item.numeroMedioPago <= 1}  onClick={!pageHandler.isLoading ? () => pageHandler.item.removerMedioPago(index) : null}>Remover</Button>}
                      />
                    </Col>
                  </Row>
                )
              }
              <Row className="m-0">
                <Button variant="primary" disabled={pageHandler.isLoading || pageHandler.item.numeroMedioPago >= 4} onClick={pageHandler.item.agregarMedioPago}>Agregar Medio de Pago</Button>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => pageHandler.setOpenModal(false)} disabled={pageHandler.isLoading}>Cerrar</Button>
              <Button variant="primary" type="submit" disabled={pageHandler.isLoading}>{pageHandler.isLoading ? "Guardando…" : "Enviar a Hacienda"}</Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <CustomAlert
            type={pageHandler.alertType}
            dismissible
            body={pageHandler.alert}
            show={pageHandler.showAlert}
            onClose={pageHandler.setShowAlert}
        />
        <Form noValidate>
          <Row>
            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
              {
                pageHandler.item.receptor.isSet ? (
                  <CustomTextField
                    id="itemCliente" label="Cliente" readOnly input={{value: receptor.nombreComercial || receptor.nombre}}
                    append={<Button variant="danger" onClick={pageHandler.item.handleCliente}>X</Button>}
                    appendLabel={<Button variant="info" size="sm" onClick={() => pageHandler.item.setShowCliente(true)}>Ver Cliente</Button>}
                  />
                ) : (
                  <CustomSearchField
                    id="searchCliente" label="Cliente" isLoading={pageHandler.item.loadingClientes} minLength={2} onSearch={handleSearchCliente}
                    onKeyDown={handleEnterCliente} onChange={pageHandler.item.handleCliente} options={pageHandler.item.clientes}
                    placeholder="Buscar cliente por nombre o identificaci&oacute;n" reference={pageHandler.item.clienteInput}
                    appendLabel={<Button variant="primary" size="sm" onClick={() => controller.session.history.push("/cliente")}>Crear</Button>}
                  />
                )
              }
              {
                pageHandler.item.linea.nuevaLinea ? (
                  <CustomTextField
                    id="itemLinea" label="Agregar L&iacute;nea" readOnly input={{value: pageHandler.item.linea.producto.detalle ?? ""}}
                    append={<Button variant="danger" onClick={pageHandler.item.linea.handleCancelarLinea}>X</Button>}
                    appendLabel={<Button variant="info" size="sm" onClick={() => pageHandler.item.setShowProducto(true)}>Ver {setBoolean(producto.isProducto) ? "Producto" : "Servicio"}</Button>}
                  />
                ) : (
                  <CustomSearchField
                    id="searchProducto" label="Agregar L&iacute;nea" isLoading={pageHandler.item.linea.loadingProductos} onSearch={handleSearchProducto}
                    onKeyDown={handleEnterProducto} onChange={handleProducto} options={pageHandler.item.linea.productos}
                    placeholder="Buscar producto por detalle o c&oacute;digo" reference={pageHandler.item.linea.productoInput}
                  />
                )
              }
              <CustomTextField id="itemCantidad" label="Cantidad" disabled={!pageHandler.item.linea.nuevaLinea} input={pageHandler.item.linea.cantidad.input} />
              <CustomTextField
                id="itemPrecioUnitario" label="Precio Unitario" disabled={!pageHandler.item.linea.nuevaLinea} input={pageHandler.item.linea.precioUnitario.input}
                append={<Form.Control as="select" custom disabled={!pageHandler.item.linea.nuevaLinea} {...pageHandler.item.linea.tipoPrecio.select}>{pageHandler.item.linea.tipoPrecio.options()}</Form.Control>}
              />
              <CustomTextField
                id="itemDescuento" label="Descuento" disabled={!pageHandler.item.linea.nuevaLinea} input={pageHandler.item.linea.descuento.input}
                append={<Form.Control as="select" custom disabled={!pageHandler.item.linea.nuevaLinea} {...pageHandler.item.linea.tipoDescuento.select}>{pageHandler.item.linea.tipoDescuento.options()}</Form.Control>}
              />
              <CustomTextField id="itemNaturalezaDescuento" label="Raz&oacute;n del Descuento" disabled={!pageHandler.item.linea.nuevaLinea || !pageHandler.item.linea.descuento.value} input={pageHandler.item.linea.naturalezaDescuento.input} />
              {
                pageHandler.item.linea.exoneracion?.numeroDocumento ? (
                  <CustomTextField
                    id="lineaExoneracion" label="Exoneraci&oacute;n" readOnly input={{value: pageHandler.item.linea.exoneracion.numeroDocumento ?? ""}}
                    append={<Button variant="danger" onClick={() => pageHandler.item.linea.setExoneracion({})}>X</Button>}
                  />
                ) : (
                  <>
                    <CustomCheckField id="itemAgregarExoneracion" label="Agregar Exoneraci&oacute;n" disabled={!pageHandler.item.linea.nuevaLinea || !pageHandler.item.receptor.isSet} input={pageHandler.item.linea.agregarExoneracion.check} />
                    {
                      pageHandler.item.linea.agregarExoneracion.value ? (
                        pageHandler.item.linea.exoneraciones.length > 0 ? (
                          pageHandler.item.linea.exoneraciones.map((exoneracion, index) => (
                            <Card key={`exoneracion${index}`} border="secondary" className="full-size mb-10">
                              <ListGroup variant="flush">
                                <ListGroup.Item><b>Documento:</b>&#160;{exoneracion.numeroDocumento} &#160; <b>Porcentaje&#160;exonerado:</b>&#160;{exoneracion.porcentajeExoneracion}  &#160; <b>Vencimiento:</b>&#160;{cleanStringDate(exoneracion.fechaVencimiento)} &#160;</ListGroup.Item>
                                <ListGroup.Item><Button variant="primary" onClick={!pageHandler.isLoading ? () => pageHandler.item.linea.setExoneracion(exoneracion) : null}>Utilizar</Button></ListGroup.Item>
                              </ListGroup>
                            </Card>
                          ))
                        ) : <CustomAlert type="warning" body="No hay exoneraciones v&#225;lidas" show />
                      ) : null
                    }
                  </>
                )
              }
              <Row className="m-0 mb-10">
                <Button variant="success" className="ml-10 mt-5" disabled={!pageHandler.item.linea.nuevaLinea} onClick={pageHandler.item.handleAgregarLinea}>{controller.session.width > 1032 || controller.session.width < 992 ? 'Agregar' : '+'}{controller.session.width > 1424 || controller.session.width < 992 ? ' L\u00ednea' : ''}</Button>
                <Button variant="secondary" className="ml-10 mt-5" disabled={!pageHandler.item.linea.nuevaLinea} onClick={pageHandler.item.linea.handleCancelarLinea}>{controller.session.width > 1246 || controller.session.width < 992 ? 'Cancelar' : 'X'}</Button>
                <Button variant="warning" className="ml-10 mt-5" onClick={() => pageHandler.item.reset()}>Eliminar</Button>
                <Button variant="primary" className="ml-10 mt-5" disabled={pageHandler.isLoading || pageHandler.item.numeroLineas === 0} onClick={!pageHandler.isLoading ? openMediosPagoModal : null}>Facturar</Button>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={9} xl={9}>
              <Accordion className="accordion-transparent">
                <Card>
                  <Card.Header>
                    <b>Precios:</b>&#160;{pageHandler.item.isMinorista.textValue()} &#160; <b>Actividad:</b>&#160;{Number(pageHandler.item.codigoActividad.value)} &#160; <b>Condici&oacute;n:</b>&#160;{pageHandler.item.condicionVenta.textValue()}
                    <Accordion.Toggle className="pt-2 ml-5" as={Button} variant="link" eventKey="0">Cambiar Valores</Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <CustomSelectField id="itemIsMinorista" label="Tipo de Precios" input={pageHandler.item.isMinorista.select} options={pageHandler.item.isMinorista.options} />
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <CustomSelectField id="itemCodigoActividad" label="C&oacute;digo de actividad" input={pageHandler.item.codigoActividad} options={pageHandler.item.codigosActividad} />
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <CustomSelectField id="itemCondicionVenta" label="Condici&oacute;n de la Venta" input={pageHandler.item.condicionVenta.select} options={pageHandler.item.condicionVenta.options} />
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <CustomSelectField id="itemCodigoMoneda" label="Moneda" input={pageHandler.item.codigoMoneda.select} options={pageHandler.item.codigoMoneda.options} />
                      </Col>
                    </Row>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion className="accordion-transparent">
                <Card>
                  <Card.Header>
                    <b>Subtotal:</b>&#160;{Number(pageHandler.item.totalVentaNeta)} &#160; <b>Impuestos:</b>&#160;{Number(pageHandler.item.totalImpuesto)} &#160; <b>Total:</b>&#160;{Number(pageHandler.item.totalComprobante)}
                    <Accordion.Toggle className="pt-2 ml-5" as={Button} variant="link" eventKey="0">Ver Desglose</Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    {
                      controller.session.width < 1400 ? (
                        <Row className="m-0">
                          <Card border="secondary" className="full-size mb-10">
                            <ListGroup variant="flush">
                              <ListGroup.Item><b>Servicios&#160;Gravados:</b>&#160;{Number(pageHandler.item.totalServGravados)} &#160; <b>Servicios&#160;Exentos:</b>&#160;{Number(pageHandler.item.totalServExentos)} &#160; <b>Servicios&#160;Exonerados:</b>&#160;{Number(pageHandler.item.totalServExonerado)}</ListGroup.Item>
                              <ListGroup.Item><b>Mercanc&iacute;as&#160;Gravadas:</b>&#160;{Number(pageHandler.item.totalMercanciasGravadas)} &#160; <b>Mercanc&iacute;as&#160;Exentas:</b>&#160;{Number(pageHandler.item.totalMercanciasExentas)} &#160; <b>Mercanc&iacute;as&#160;Exoneradas:</b>&#160;{Number(pageHandler.item.totalMercExonerada)}</ListGroup.Item>
                              <ListGroup.Item><b>Total&#160;Gravado:</b>&#160;{Number(pageHandler.item.totalGravado)} &#160; <b>Total&#160;Exento:</b>&#160;{Number(pageHandler.item.totalExento)} &#160; <b>Total&#160;Exonerado:</b>&#160;{Number(pageHandler.item.totalExonerado)} &#160; <b>Total&#160;Venta:</b>&#160;{Number(pageHandler.item.totalVenta)}</ListGroup.Item>
                              <ListGroup.Item><b>Descuentos:</b>&#160;{Number(pageHandler.item.totalDescuentos)} &#160; <b>Venta&#160;Neta:</b>&#160;{Number(pageHandler.item.totalVentaNeta)} &#160; <b>Impuestos:</b>&#160;{Number(pageHandler.item.totalImpuesto)} &#160; <b>Total&#160;Comprobante:</b>&#160;{Number(pageHandler.item.totalComprobante)}</ListGroup.Item>
                            </ListGroup>
                          </Card>
                        </Row>
                      ) : (
                        <Table>
                          <tbody>
                            <tr>
                              <td>Servicios Gravados</td>
                              <td>{Number(pageHandler.item.totalServGravados)}</td>
                              <td>Servicios Exentos</td>
                              <td>{Number(pageHandler.item.totalServExentos)}</td>
                              <td>Servicios Exonerados</td>
                              <td>{Number(pageHandler.item.totalServExonerado)}</td>
                            </tr>
                            <tr>
                              <td>Mercanc&iacute;as Gravadas</td>
                              <td>{Number(pageHandler.item.totalMercanciasGravadas)}</td>
                              <td>Mercanc&iacute;as Exentas</td>
                              <td>{Number(pageHandler.item.totalMercanciasExentas)}</td>
                              <td>Mercanc&iacute;as Exoneradas</td>
                              <td>{Number(pageHandler.item.totalMercExonerada)}</td>
                            </tr>
                            <tr>
                              <td>Total Gravado</td>
                              <td>{Number(pageHandler.item.totalGravado)}</td>
                              <td>Total Exento</td>
                              <td>{Number(pageHandler.item.totalExento)}</td>
                              <td>Total Exonerado</td>
                              <td>{Number(pageHandler.item.totalExonerado)}</td>
                            </tr>
                            <tr>
                              <td>Total Venta</td>
                              <td>{Number(pageHandler.item.totalVenta)}</td>
                              <td>Descuentos</td>
                              <td>{Number(pageHandler.item.totalDescuentos)}</td>
                              <td>Venta Neta</td>
                              <td>{Number(pageHandler.item.totalVentaNeta)}</td>
                            </tr>
                            <tr>
                              <td>Impuestos</td>
                              <td>{Number(pageHandler.item.totalImpuesto)}</td>
                              <td>Total Comprobante</td>
                              <td>{Number(pageHandler.item.totalComprobante)}</td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </Table>
                      )
                    }
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion className="accordion-transparent">
                <Card>
                  <Card.Header>
                    <b>L&iacute;neas:</b>&#160;{pageHandler.item.numeroLineas}
                    <Accordion.Toggle className="pt-2 ml-5" as={Button} variant="link" eventKey="0">Ver Detalle</Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <>
                      {
                        controller.session.width < 1400 ? (
                          pageHandler.item.lineas.map((element, index) =>
                            <Row className="m-0" key={`linea${index}`}>
                              <Card border="secondary" className="full-size mb-10">
                                <ListGroup variant="flush">
                                  <ListGroup.Item><b>L&iacute;nea:</b>&#160;{index + 1} &#160; <b>C&oacute;digo&#160;Cabys:</b>&#160;{element.codigo}</ListGroup.Item>
                                  <ListGroup.Item><b>Detalle:</b> {element.detalle}</ListGroup.Item>
                                  <ListGroup.Item><b>Cantidad:</b>&#160;{Number(element.cantidad)} &#160; <b>Precio:</b>&#160;{Number(element.precioUnitario)} &#160; <b>Descuento:</b>&#160;{Number(element.descuento[0].montoDescuento)}</ListGroup.Item>
                                  <ListGroup.Item><b>Subtotal:</b>&#160;{Number(element.subTotal)} &#160; <b>Impuestos:</b>&#160;{Number(element.impuestoNeto)} &#160; <b>Total:</b>&#160;{Number(element.montoTotalLinea)}</ListGroup.Item>
                                  <ListGroup.Item><Button variant="danger" onClick={!pageHandler.isLoading ? () => pageHandler.item.removerLinea(index) : null}>Remover</Button></ListGroup.Item>
                                </ListGroup>
                              </Card>
                            </Row>
                          )
                        ) : (
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>&#35;</th>
                                <th>C&oacute;digo Cabys</th>
                                <th>Detalle</th>
                                <th>Cantidad</th>
                                <th>Precio</th>
                                <th>Descuento</th>
                                <th>Subtotal</th>
                                <th>Impuestos</th>
                                <th>Total</th>
                                <th>L&iacute;neas: {pageHandler.item.numeroLineas}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                pageHandler.item.lineas.map((element, index) =>
                                  <tr key={`linea${index}`}>
                                    <td>{index + 1}</td>
                                    <td>{element.codigo}</td>
                                    <td>{element.detalle}</td>
                                    <td>{Number(element.cantidad)}</td>
                                    <td>{Number(element.precioUnitario)}</td>
                                    <td>{Number(element.descuento[0].montoDescuento)}</td>
                                    <td>{Number(element.subTotal)}</td>
                                    <td>{Number(element.impuestoNeto)}</td>
                                    <td>{Number(element.montoTotalLinea)}</td>
                                    <td><Button variant="danger" onClick={!pageHandler.isLoading ? () => pageHandler.item.removerLinea(index) : null}>Remover</Button></td>
                                  </tr>
                                )
                              }
                            </tbody>
                          </Table>
                        ) 
                      }
                    </>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
  return <Container className="full-container"><CustomAlert type="warning" body="Seleccione una sucursal" show/></Container>;
}