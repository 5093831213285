import {useState, useRef} from "react";
import {CustomOption} from "../components";
import {useContribuyente, useFormInput, useFormSelect, useLineaVenta} from "./index";
import {tiposCliente, condicionesVenta, mediosPago, tiposComprobante} from "../constants";
import {roundNumber, multiplyNumbers, divideNumbers, subtractNumbers, addNumbers, cutString} from "../helpers";

export const useVenta = () => {
  const linea = useLineaVenta();
  const [loadingClientes, setLoadingClientes] = useState(false);
  const [showCliente, setShowCliente] = useState(false);
  const [showProducto, setShowProducto] = useState(false);
  const [clientes, setClientes] = useState([]);
  const clienteInput = useRef();
  const [cajaId, setCajaId] = useState(0);
  const [fecha, setFecha] = useState("");
  const [codigoActividad, setCodigoActividad] = useState("");
  const [totalServGravados, setTotalServGravados] = useState("0.00000");
  const [totalServExentos, setTotalServExentos] = useState("0.00000");
  const [totalServExonerado, setTotalServExonerado] = useState("0.00000");
  const [totalMercanciasGravadas, setTotalMercanciasGravadas] = useState("0.00000");
  const [totalMercanciasExentas, setTotalMercanciasExentas] = useState("0.00000");
  const [totalMercExonerada, setTotalMercExonerada] = useState("0.00000");
  const [totalGravado, setTotalGravado] = useState("0.00000");
  const [totalExento, setTotalExento] = useState("0.00000");
  const [totalExonerado, setTotalExonerado] = useState("0.00000");
  const [totalVenta, setTotalVenta] = useState("0.00000");
  const [totalDescuentos, setTotalDescuentos] = useState("0.00000");
  const [totalVentaNeta, setTotalVentaNeta] = useState("0.00000");
  const [totalImpuesto, setTotalImpuesto] = useState("0.00000");
  // TODO:
  // const [totalIVADevuelto, setTotalIVADevuelto] = useState("0.00000");
  // const [totalOtrosCargos, setTotalOtrosCargos] = useState("0.00000");
  const [totalComprobante, setTotalComprobante] = useState("0.00000");
  const [numeroLineas, setNumeroLineas] = useState(0);
  const [numeroMedioPago, setNumeroMedioPago] = useState(1);
  const [lineas, setLineas] = useState([]);
  const [medioPago, setMedioPago] = useState(["01"]);
  const [medioPagoMonto, setMedioPagoMonto] = useState(["0"]);
  const observaciones = useFormInput("");
  const isMinorista = useFormSelect(true, tiposCliente);
  const condicionVenta = useFormSelect("01", condicionesVenta);
  const emisor = useContribuyente();
  const receptor = useContribuyente();
  const codigosActividad = useRef([]);
  const lineasAux = useRef([]);
  const medioPagoAux = useRef(["01"]);
  const medioPagoMontoAux = useRef(["0"]);
  // TODO:
  const codigoMoneda = useFormSelect("CRC", () => [
    {key: "1cm", text: "CRC", value: "CRC"},
    {key: "2cm", text: "USD", value: "USD"}
  ]);

  const handleCodigoActividad = (e) => setCodigoActividad(e.target.value);

  const displayCodigosActividad = () => codigosActividad.current.map(element => <option key={element.codigoActividad} value={element.codigoActividad}>{element.codigoActividad}</option>)

  const displayMediosPago = () => mediosPago().map(element => <CustomOption key={element.key} item={element} />)

  const initializeMedioPago = () => {
    medioPagoAux.current = ["01"];
    medioPagoMontoAux.current = [Number(totalComprobante)];
    setMedioPago(["01"]);
    setMedioPagoMonto([Number(totalComprobante)]);
    setNumeroMedioPago(1);
  }

  const agregarMedioPago = () => {
    medioPagoAux.current.push("01");
    medioPagoMontoAux.current.push("0");
    setMedioPago(medioPagoAux.current);
    setMedioPagoMonto(medioPagoMontoAux.current);
    setNumeroMedioPago(medioPagoAux.current.length);
  }

  const removerMedioPago = (index) => {
    medioPagoAux.current.splice(index, 1);
    medioPagoMontoAux.current.splice(index, 1);
    setMedioPago([...medioPagoAux.current]);
    setMedioPagoMonto([...medioPagoMontoAux.current]);
    setNumeroMedioPago(medioPagoAux.current.length);
  }

  const changeMedioPago = (index, value) => {
    medioPagoAux.current[index] = value;
    setMedioPago([...medioPagoAux.current]);
  }

  const changeMedioPagoMonto = (index, value) => {
    medioPagoMontoAux.current[index] = value;
    setMedioPagoMonto([...medioPagoMontoAux.current]);
  }

  const reset = (allFields = true) => {
    lineasAux.current = [];
    medioPagoAux.current = ["01"];
    medioPagoMontoAux.current = ["0"];
    setFecha("");
    setNumeroLineas(0);
    setNumeroMedioPago(1);
    setLineas([]);
    setMedioPago(["01"]);
    setMedioPagoMonto(["0"]);
    setTotalServGravados("0.00000");
    setTotalServExentos("0.00000");
    setTotalServExonerado("0.00000");
    setTotalMercanciasGravadas("0.00000");
    setTotalMercanciasExentas("0.00000");
    setTotalMercExonerada("0.00000");
    setTotalGravado("0.00000");
    setTotalExento("0.00000");
    setTotalExonerado("0.00000");
    setTotalVenta("0.00000");
    setTotalDescuentos("0.00000");
    setTotalVentaNeta("0.00000");
    setTotalImpuesto("0.00000");
    setTotalComprobante("0.00000");
    receptor.reset();
    linea.handleCancelarLinea();
    observaciones.setValue("");
    if (allFields) {
      setCodigoActividad(codigosActividad.current[0].codigoActividad);
      isMinorista.setValue(true);
      condicionVenta.setValue("01");
    }
  }

  const agregarLinea = (lineaData) => {
    const cantidad = roundNumber(lineaData.cantidad || 1, 3);
    const tarifaImpuesto = roundNumber(lineaData.producto.impuestoTarifa, 2);
    const porcentajeImpuesto = divideNumbers(tarifaImpuesto, 100);
    let precioUnitario = roundNumber(lineaData.precioUnitario || 0);
    if (lineaData.tipoPrecio === "total") {
      precioUnitario = divideNumbers(precioUnitario, addNumbers(1, porcentajeImpuesto));
    }
    const montoTotal = multiplyNumbers(cantidad, precioUnitario);
    let descuento = roundNumber(lineaData.descuento || 0);
    if (lineaData.tipoDescuento === "porcentaje") {
      descuento = multiplyNumbers(divideNumbers(descuento, 100), montoTotal);
    }
    const subTotal = subtractNumbers(montoTotal, descuento);
    const montoImpuesto = multiplyNumbers(porcentajeImpuesto, subTotal);
    let exoneracion = false;
    if (lineaData.exoneracion?.tipoDocumento) {
      exoneracion = {
        tipoDocumento: lineaData.exoneracion.tipoDocumento.codigo,
        numeroDocumento: lineaData.exoneracion.numeroDocumento,
        nombreInstitucion: lineaData.exoneracion.nombreInstitucion,
        fechaEmision: (new Date(lineaData.exoneracion.fechaEmision)).toISOString(),
        porcentajeExoneracion: lineaData.exoneracion.porcentajeExoneracion,
        montoExoneracion: multiplyNumbers(divideNumbers(lineaData.exoneracion.porcentajeExoneracion, 100, 2), subTotal)
      };
    }
    const impuestoExonerado = exoneracion ? exoneracion.montoExoneracion : 0;
    const impuestoNeto = subtractNumbers(montoImpuesto, impuestoExonerado);
    const lineaFactura = {
      productoId: lineaData.producto.id,
      isProducto: lineaData.producto.isProducto,
      codigo: cutString(lineaData.producto.codigo, 13),
      codigoComercial: [{
        tipo: "04",
        codigo: cutString(lineaData.producto.codigoComercial, 20)
      }],
      cantidad: cantidad,
      unidadMedida: lineaData.producto.unidadMedida,
      detalle: cutString(lineaData.producto.detalle, 200),
      precioUnitario: precioUnitario,
      montoTotal: montoTotal,
      descuento: [{
        montoDescuento: descuento,
        naturalezaDescuento: cutString(lineaData.naturalezaDescuento, 80)
      }],
      subTotal: subTotal,
      impuesto: [{
        codigo: lineaData.producto.impuestoCodigoImpuesto,
        codigoTarifa: lineaData.producto.impuestoCodigoTarifa,
        tarifa: tarifaImpuesto,
        monto: montoImpuesto,
        ...exoneracion ? {exoneracion} : {}
      }],
      impuestoNeto: impuestoNeto,
      montoTotalLinea: addNumbers(subTotal, impuestoNeto)
    }
    lineasAux.current.push(lineaFactura);
    setLineas(lineasAux.current);
    setNumeroLineas(lineasAux.current.length);
    calcularTotales();
  }

  const removerLinea = (index) => {
    lineasAux.current.splice(index, 1);
    setLineas(lineasAux.current);
    setNumeroLineas(lineasAux.current.length);
    calcularTotales();
  }

  const calcularTotales = () => {
    let serviciosGravados = 0;
    let serviciosExentos = 0;
    let serviciosExonerados = 0;
    let mercanciasGravadas = 0;
    let mercanciasExentas = 0;
    let mercanciasExoneradas = 0;
    let descuentos = 0;
    let impuestos = 0;
    lineasAux.current.forEach(lineaData => {
      descuentos += Number(lineaData.descuento[0].montoDescuento);
      impuestos += Number(lineaData.impuestoNeto);
      if (lineaData.isProducto) {
        if (Number(lineaData.impuestoNeto) > 0) {
          if (lineaData.impuesto[0].exoneracion) {
            const porcentajeExonerado = divideNumbers(lineaData.impuesto.exoneracion.porcentajeExoneracion, lineaData.impuesto.tarifa, 7);
            const montoExonerado = multiplyNumbers(porcentajeExonerado, lineaData.montoTotal);
            mercanciasExoneradas += montoExonerado;
            mercanciasGravadas += subtractNumbers(lineaData.montoTotal, montoExonerado);
          } else {
            mercanciasGravadas += Number(lineaData.montoTotal);
          }
        } else {
          if (lineaData.impuesto[0].exoneracion) {
            mercanciasExoneradas += Number(lineaData.montoTotal);
          } else {
            // mercanciasExentas += Number(lineaData.montoTotal);
            mercanciasGravadas += Number(lineaData.montoTotal);
          }
        }
      } else {
        if (Number(lineaData.impuestoNeto) > 0) {
          if (lineaData.impuesto[0].exoneracion) {
            const porcentajeExonerado = divideNumbers(lineaData.impuesto.exoneracion.porcentajeExoneracion, lineaData.impuesto.tarifa, 7);
            const montoExonerado = multiplyNumbers(porcentajeExonerado, lineaData.montoTotal);
            serviciosExonerados += montoExonerado;
            serviciosGravados += subtractNumbers(lineaData.montoTotal, montoExonerado);
          } else {
            serviciosGravados += Number(lineaData.montoTotal);
          }
        } else {
          if (lineaData.impuesto[0].exoneracion) {
            serviciosExonerados += Number(lineaData.montoTotal);
          } else {
            // serviciosExentos += Number(lineaData.montoTotal);
            serviciosGravados += Number(lineaData.montoTotal);
          }
        }
      }
    });
    serviciosGravados = roundNumber(serviciosGravados);
    serviciosExentos = roundNumber(serviciosExentos);
    serviciosExonerados = roundNumber(serviciosExonerados);
    mercanciasGravadas = roundNumber(mercanciasGravadas);
    mercanciasExentas = roundNumber(mercanciasExentas);
    mercanciasExoneradas = roundNumber(mercanciasExoneradas);
    descuentos = roundNumber(descuentos);
    impuestos = roundNumber(impuestos);
    const gravado = addNumbers(serviciosGravados, mercanciasGravadas);
    const exento = addNumbers(serviciosExentos, mercanciasExentas);
    const exonerado = addNumbers(serviciosExonerados, mercanciasExoneradas);
    const venta = addNumbers(addNumbers(gravado, exento), exonerado);
    const ventaNeta = subtractNumbers(venta, descuentos);
    const comprobante = addNumbers(ventaNeta, impuestos);
    setTotalServGravados(serviciosGravados);
    setTotalServExentos(serviciosExentos);
    setTotalServExonerado(serviciosExonerados);
    setTotalMercanciasGravadas(mercanciasGravadas);
    setTotalMercanciasExentas(mercanciasExentas);
    setTotalMercExonerada(mercanciasExoneradas);
    setTotalGravado(gravado);
    setTotalExento(exento);
    setTotalExonerado(exonerado);
    setTotalVenta(venta);
    setTotalDescuentos(descuentos);
    setTotalVentaNeta(ventaNeta);
    setTotalImpuesto(impuestos);
    setTotalComprobante(comprobante);
  }

  const getObject = () => {
    const fechaEmision = new Date();
    setFecha(fechaEmision.toLocaleString('es-US'));
    const object = {
      codigoActividad,
      fechaEmision: fechaEmision.toISOString(),
      medioPago,
      condicionVenta: condicionVenta.value,
      emisor: emisor.getObject,
      detalleServicio: {
        lineaDetalle: lineas.map(({isProducto, ...props}, index) => ({numeroLinea: index + 1, ...props}))
      },
      resumenFactura: {
        // codigoTipoMoneda: "USD",
        totalServGravados,
        totalServExentos,
        totalServExonerado,
        totalMercanciasGravadas,
        totalMercanciasExentas,
        totalMercExonerada,
        totalGravado,
        totalExento,
        totalExonerado,
        totalVenta,
        totalDescuentos,
        totalVentaNeta,
        totalImpuesto,
        totalComprobante
      }
    };
    if (receptor.isSet) {
      object.receptor = receptor.getObject;
    }
    if (observaciones.value !== "") {
      object.otros = {otroTexto: [observaciones.value]};
    }
    return object;
  }

  const handleCliente = (selected = []) => {
    if (selected[0]) {
      receptor.setObject(selected[0].object);
    } else {
      receptor.setObject({identificacion: {}, ubicacion: {}, telefono: {}});
    }
  };

  const handleAgregarLinea = () => {
    agregarLinea(linea.getLinea);
    linea.handleCancelarLinea();
  };

  const getData = () => ({
    fecha,
    codigoActividad,
    totalServGravados,
    totalServExentos,
    totalServExonerado,
    totalMercanciasGravadas,
    totalMercanciasExentas,
    totalMercExonerada,
    totalGravado,
    totalExento,
    totalExonerado,
    totalVenta,
    totalDescuentos,
    totalVentaNeta,
    totalImpuesto,
    totalComprobante,
    numeroLineas,
    numeroMedioPago,
    lineas,
    medioPago,
    medioPagoMonto,
    isMinorista: isMinorista.value,
    condicionVenta: condicionVenta.value,
    receptor: receptor.getObject,
    linea: linea.getData(),
  })

  const setObject = (cuenta, sucursal, codigos, data = {}) => {
    codigos.sort((a,b) => (a.isPrincipal > b.isPrincipal) ? 1 : ((b.isPrincipal > a.isPrincipal) ? -1 : 0));
    codigosActividad.current = codigos;
    setCodigoActividad(data.codigoActividad ?? codigos[0].codigoActividad);
    emisor.setObject(cuenta, sucursal);
    setFecha(data.fecha ?? "");
    setTotalServGravados(data.totalServGravados ?? "0.00000");
    setTotalServExentos(data.totalServExentos ?? "0.00000");
    setTotalServExonerado(data.totalServExonerado ?? "0.00000");
    setTotalMercanciasGravadas(data.totalMercanciasGravadas ?? "0.00000");
    setTotalMercanciasExentas(data.totalMercanciasExentas ?? "0.00000");
    setTotalMercExonerada(data.totalMercExonerada ?? "0.00000");
    setTotalGravado(data.totalGravado ?? "0.00000");
    setTotalExento(data.totalExento ?? "0.00000");
    setTotalExonerado(data.totalExonerado ?? "0.00000");
    setTotalVenta(data.totalVenta ?? "0.00000");
    setTotalDescuentos(data.totalDescuentos ?? "0.00000");
    setTotalVentaNeta(data.totalVentaNeta ?? "0.00000");
    setTotalImpuesto(data.totalImpuesto ?? "0.00000");
    setTotalComprobante(data.totalComprobante ?? "0.00000");
    setNumeroLineas(data.numeroLineas ?? 0);
    setNumeroMedioPago(data.numeroMedioPago ?? 1);
    setLineas(data.lineas ?? []);
    setMedioPago(data.medioPago ?? ["01"]);
    setMedioPagoMonto(data.medioPagoMonto ?? ["0"]);
    isMinorista.setValue(data.isMinorista ?? true);
    condicionVenta.setValue(data.condicionVenta ?? "01");
    lineasAux.current = data.lineas ?? [];
    if (data.receptor) {
      receptor.setObject(data.receptor);
    } else {
      receptor.reset();
    }
    if (data.linea) {
      linea.setData(data.linea);
    } else {
      linea.handleCancelarLinea();
    }
  }

  const getCajaObject = () => {
    const cajaObject = {
      totalEfectivo: 0,
      totalTarjeta: 0,
      totalCheque: 0,
      totalTransferencia: 0,
      totalRecaudadoTerceros: 0,
      totalOtros: 0
    };
    medioPago.forEach((medioPagoVenta, index) => cajaObject[mediosPago().find(medioPagoObject => medioPagoObject.value === medioPagoVenta).valueCaja] = medioPagoMonto[index]);
    return cajaObject;
  }

  const mapObject = (data) => ({
    ...data,
    lineas: data.detalleServicio?.lineaDetalle ?? [],
    tipoDocumento: tiposComprobante().find(item => item.value === data.numeroConsecutivo.substring(8, 10))?.text ?? "",
    condicion: condicionesVenta().find(element => element.value === data.condicionVenta)?.text ?? "",
    medios: data.medioPago.map(medio => mediosPago().find(element => element.value === medio)?.text)?.toString(),
    fecha: data.fechaEmision ? new Date(data.fechaEmision).toLocaleString('es-US') : "",
    isSet: true
  });

  return {
    codigoActividad: {value: codigoActividad, onChange: handleCodigoActividad},
    emisor,
    receptor,
    cajaId,
    fecha,
    condicionVenta,
    codigoMoneda,
    observaciones,
    isMinorista,
    lineas,
    numeroLineas,
    numeroMedioPago,
    totalServGravados,
    totalServExentos,
    totalServExonerado,
    totalMercanciasGravadas,
    totalMercanciasExentas,
    totalMercExonerada,
    totalGravado,
    totalExento,
    totalExonerado,
    totalVenta,
    totalDescuentos,
    totalVentaNeta,
    totalImpuesto,
    totalComprobante,
    medioPago,
    medioPagoMonto,
    loadingClientes,
    clientes,
    clienteInput,
    linea,
    showCliente,
    showProducto,
    setShowProducto,
    setShowCliente,
    setClientes,
    setLoadingClientes,
    codigosActividad: displayCodigosActividad,
    mediosPago: displayMediosPago,
    setCajaId,
    agregarLinea,
    removerLinea,
    initializeMedioPago,
    agregarMedioPago,
    removerMedioPago,
    changeMedioPago,
    changeMedioPagoMonto,
    reset,
    setObject,
    getObject,
    getCajaObject,
    handleCliente,
    handleAgregarLinea,
    getData,
    mapObject
  };
}